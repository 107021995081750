export default {
    "name": "New Multiple Choice",
    "instructions": "Your Instructions Here...",
    "selectMultiple" : false,
    "audioId": null,
    "choices": [
        {
            "value": "Choice 1",
            "isCorrect" : true
        },
        {
            "value": "Choice 2",
            "isCorrect" : false
        },
        {
            "value": "Choice 3",
            "isCorrect" : false
        }
    ]
}