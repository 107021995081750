const newRow = {
	audioId: null,
	choices: [
		{	
			content: 'พ', // display string or uuid
			correct: false
		},
		{	
			content: 'ะ', // display string or uuid 
			correct: true
		}
	]
}

export default newRow