export default {
    "name": "ผีเสื้อ / Butterfly",
    "instructions": "ลำดับตั้งแต่แรกจนถึงสุดท้าย / Order First to Last",
    "images": [
        {
            "id": "9c9058e0-f8ec-11ee-95b9-47b383c910e1"
        },
        {
            "id": "e6e76a00-f8ec-11ee-95b9-47b383c910e1"
        },
        {
            "id": "39d545c0-f8ed-11ee-95b9-47b383c910e1"
        },
        {
            "id": "4dd57870-f8ec-11ee-8881-b3d50f9242a3"
        }
    ],
    "audioId": null
}